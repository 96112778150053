import React from 'react';

// External Components
import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { Spacer } from 'components';

import {
  Header,
  ProductCategory,
  ProductCategorySkeleton
} from 'sections/shopPage';

// Hooks
import {
  useKisShopPageData,
  useProductCategories,
  useKisProductViewPageData
} from 'boxenStopHooks';
import { useShopPageData } from 'sections/shopPage';

// helper function
import { wrapIndex } from 'helper';
import { prepareProductToDisplay } from 'sections/shopPage/productCategory/helper';

const ShopShipping = () => {
  // Static Data
  const { seo, header, product } = useShopPageData();

  // Dynamic Data from API
  const { data, loading } = useProductCategories();

  const productToDisplay = prepareProductToDisplay(data?.productCategories);

  const { header: cmsHeader } = useKisShopPageData();
  const { product_card: cmsProductCard } = useKisProductViewPageData();

  return (
    <>
      <SEO {...seo} />
      <Header data={cmsHeader} staticData={header} />
      <Spacer
        height={['15.6rem', '23rem', '31.1rem', '20rem', '13.8rem', '17.7rem']}
      />
      {loading ? (
        <ProductCategorySkeleton
          cardBackground={product.categories[0].bgColor}
        />
      ) : (
        productToDisplay.map((item, index) => (
          <ProductCategory
            key={index}
            data={item}
            productPageData={cmsProductCard}
            categoryColor={
              product.categories[wrapIndex(product.categories.length, index)]
                .bgColor
            }
          />
        ))
      )}
    </>
  );
};

export default injectIntl(ShopShipping);
